import styled from '@emotion/styled'

export const PostContainer = styled.div`
  padding: 0 0 3rem;
  > div.mixed {
    display: flex;
    justify-content: space-between;
    > div {
      .content.press {
        padding-right: 2rem;
        max-width: 952px;
      }
      &.post-image {
        width: 120px;
        img {
          width: 120px;
        }
      }
      &.post-image-mobile {
        display: none;
      }
    }
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      display: block;
      > div {
        .content.press {
          padding-right: 0;
        }
        &:first-of-type {
          padding-right: 0;
          padding-bottom: 2rem;
        }
        &.post-image {
          display: none;
        }
        &.post-image-mobile {
          display: block;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  &:not(:first-of-type) {
    &:before {
      content: "";
      display: block;
      width: 280px;
      margin: 0 auto;
      border-top: 1px solid ${props => props.theme.colors.grey};
      height: 1px;
      margin-bottom: 4rem;
    }
  }
  
  h2.title {
    margin: 0 0 1rem 0;
    font-weight: normal;
    font-size: 13px;
    font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 
    'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 
    'Segoe UI Emoji', 'Segoe UI Symbol';
    p {
      margin: 0;
      text-align: left;
    }
  }
  div.title {
    h2 {
      font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 
      'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 
      'Segoe UI Emoji', 'Segoe UI Symbol';
      margin: 0 0 .2rem 0;
      font-weight: normal;
      color: #494949;
      font-size: 26px;
    }
    p {
      font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 
      'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 
      'Segoe UI Emoji', 'Segoe UI Symbol';
      margin: 0 0 .2rem 0;
      font-size: 16px;
      color: #595C62;
    }
  }
  h3.sub_title {
    margin: 0;
    font-weight: normal;
    font-size: 13px;
    font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 
    'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 
    'Segoe UI Emoji', 'Segoe UI Symbol';
  }
  .credits {
    margin-bottom: 1rem;
    p {
      margin: .1rem 0;
      font-weight: normal;
      font-size: 13px;
      font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 
      'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 
      'Segoe UI Emoji', 'Segoe UI Symbol';
      text-align: left;
    }
  }
  .credits {
    p {
      margin: 0;
      font-weight: normal;
      font-size: 13px;
      font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 
      'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 
      'Segoe UI Emoji', 'Segoe UI Symbol';
      line-height: 1rem;
      padding: .2rem 0;
      a, a:hover {
        font-style: normal;
        font-weight: normal;
        color: ${props => props.theme.colors.text};
        font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 
        'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 
        'Segoe UI Emoji', 'Segoe UI Symbol';
        text-decoration: underline;
      }
      text-align: left;
      em, strong {
        font-size: font-size: 13px;
      }
    }
  }
  .content, .legend {
    margin-bottom: 2rem;
    [data-oembed-type="video"] {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%!important;
        height: 100%!important;
      }
    }
    p {
      margin: 0;
      font-weight: normal;
      font-size: 16px;
      font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 
      'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 
      'Segoe UI Emoji', 'Segoe UI Symbol';
      line-height: 1.4rem;
      padding: .2rem 0;
      color: #494949;
      a, a:hover {
        font-style: normal;
        font-weight: normal;
        color: ${props => props.theme.colors.text};
        font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 
        'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 
        'Segoe UI Emoji', 'Segoe UI Symbol';
        text-decoration: underline;
        text-transform: uppercase;
      }
      text-align: justify;
      em {
        font-size: 13px;
      }
    }
    img {
      max-width: 100%;
      display: block; 
      margin: 1rem 0;
    }
    &.legend {
      p:first-of-type {
        font-size: 13px;
        padding-bottom: .5rem;
      }
    }
  }
  &.listing {
    h2.title {
      margin: 0 0 1rem 0;
      font-weight: bold;
      font-size: 22px;
      font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 
      'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 
      'Segoe UI Emoji', 'Segoe UI Symbol';
    }
    .content {
      .imageLink {
        width: 100%;
        display: block;
      }
      img {
        width: 100%;
        margin: 0 auto;
        display: block;
      }
    }
  }
`;