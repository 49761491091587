import React from 'react'
import { graphql } from 'gatsby'
// import Img from "gatsby-image";
// import { theme } from "../styles";
import styled from '@emotion/styled'
import { Wrapper, Header } from '../components'
import website from '../../config/website'
import SEO from '../components/SEO'
import PressPost from '../components/PressPost'

const IndexWrapper = Wrapper.withComponent('main')
const PageWrapper = styled.div`
  ${'' /* display:flex; */}
  ${'' /* p {
    margin-bottom:.5rem;
  } */}
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    > div {
      width: 30%!important;
      &:last-of-type {
        width: 70%!important;
        p {
          font-size: 14px
        }
      }
      &:first-of-type {
        padding-right: 1.5rem
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    display: block;
    > div {
      width: 100%!important;
      &:last-of-type {
        width: 100%!important;
        padding-top: 1.5rem;
        p {
          font-size: 18px
        }
      }
      &:first-of-type {
        padding-right: 0
      }
    }
  }
`
const Press = ({ pageContext: { locale }, location, data: { pressPosts } }) => {
  const context = { slug: "press", parent: "" };
  // console.log(pressPosts)
  return (
    <React.Fragment>
      <SEO
        pathname={location.pathname}
        locale={locale}
        title={`Disch Rémy - Press`}
        desc={`Press releases`}
      />
      <Header {...context} />
      <IndexWrapper id={website.skipNavId}>
        <PageWrapper>
        {pressPosts.edges.map((item, index) => {
          return <PressPost {...item} key={`post-${index}`} />
        })}
        </PageWrapper>
      </IndexWrapper>
    </React.Fragment>
  );
};

export default Press;

export const pageQuery = graphql`
  query PressPosts {
    pressPosts: allPrismicPresse(sort: {fields: data___date, order: DESC}) {
      edges {
        node {
          data {
            contenu {
              html
            }
            image {
              url
            }
            image_mobile {
              url
            }
            titre {
              html
              text
            }
          }
        }
      }
    }
  }
`